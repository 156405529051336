@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}
.localBtn:hover {
  background-color: white !important;
  color: #496c74 !important;
  border: 2px solid #496c74;
}
