.whiteShadow {
  -webkit-box-shadow: 0px 0px 15px 0px #f5f5f5;
  box-shadow: 0px 0px 15px 0px #f5f5f5;
}
.greyWhiteHover:hover{
  background-color: #496c74;
  color: white;
}
.uploadBox {
  border: 2px dotted #496c74;
  min-height: 200px;
}
.uploadContainer {
  border: 1px solid #496c74;
  height: 350px;
}
.react-datetime-picker__wrapper {
  border: none !important;
}

.names {
  font-size: 50px;
}

@media screen and (max-width: 600px) {
  .names{
    font-size: 32px !important;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
.tabBtn{
  height: 50px;
}
@media screen and (max-width: 600px) {
  .tabBtn{
    height: 100px;
    text-align: center;
    font-size: 26px !important;
  }
}