body {
  margin: 0;
  font-family: 'Amatic SC', cursive;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Varela Round', sans-serif;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer{
  cursor: pointer !important;
}