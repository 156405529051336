.btnok {
  position: static;
  height: 100px;
  width: 100px;
  top: 15%;
  left: 100% !important;
  border-radius: 50%;
  background-color: #496c74;
  border: 3px solid white;
  z-index: 6777;
  font-size: 18px;
  font-weight: bold;
}

.savebtn {
  position: static;
  height: 100px;
  width: 100px;
  top: 15%;
  left: 100% !important;
  border-radius: 50%;
  background-color: #42a6bd;
  border: 3px solid white;
  z-index: 6777;
  font-size: 18px;
  font-weight: bold;
}
.savebtn:hover {
  background-color: white;
  color: #496c74 !important;
  border: 2px dotted #42a6bd;
}
.btnok:hover {
  background-color: white;
  color: #496c74 !important;
  border: 2px dotted #496c74;
}
.floatingContainer{
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0 !important;
  right: 0;
}